//@ts-nocheck
import React from 'react'

import { globalClickHandler } from '@/helpers/globalClickHandler'
import {
  Body,
  Button,
  CloudinaryImage,
  Layout,
  TitleLarge,
  TitleSmall,
} from 'ethos-design-system'
import { EVENT_NAMES } from 'lib/@getethos/analytics/constants'
import PropTypes from 'prop-types'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../global/Markdown'
import styles from './SupportedImage.module.scss'

const background = {
  grey: 'var(--GrayLightHover--opaque)',
  white: 'var(--White)',
}

const SupportedImage = ({ moduleData }) => {
  const {
    groupImage,
    imageStyle,
    imageAlt,
    hideMediaMobile,
    eyebrow,
    displayEyebrow,
    heading,
    subHeading,
    displaySubHeading,
    displayCtaPosition,
    displayCta,
    ctaStyle,
    ctaLabel,
    clickthroughUrl,
    newTab,
    backgroundColor,
    secondaryImage,
  } = moduleData

  let utmSource = 'none'
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (urlSearchParams.has('utm_source')) {
      utmSource = urlSearchParams.get('utm_source')
    }
  }

  let cta = false
  if (ctaLabel && displayCta) {
    if (ctaStyle === 'button') {
      cta = (
        <a
          href={clickthroughUrl}
          onClick={(e) => {
            e.preventDefault()
            globalClickHandler({
              event: e,
              analyticsEvent: {
                name: EVENT_NAMES.CTA_CLICKED,
                properties: {
                  module: 'Supported Image',
                  ctaLabel,
                  ctaPosition: displayCtaPosition,
                  clickthroughUrl: clickthroughUrl,
                  utmSource: utmSource,
                },
              },
              urlProps: {
                url: clickthroughUrl,
                target: newTab ? '_blank' : '_self',
              },
              callback: () => {
                if (!newTab) {
                  window.scrollTo(0, 0)
                }
              },
            })
          }}
        >
          <Button.Medium.Black>{ctaLabel}</Button.Medium.Black>
        </a>
      )
    } else {
      cta = (
        <div className={styles.textCta}>
          <a
            href={clickthroughUrl}
            onClick={(e) => {
              e.preventDefault()
              cmsModuleAnalytics.ctaClicked({
                properties: {
                  module: 'Supported Image',
                  ctaLabel,
                  ctaPosition: displayCtaPosition,
                  clickthroughUrl: clickthroughUrl,
                  utmSource: utmSource,
                },
              })
              if (!newTab) {
                window.scrollTo(0, 0)
              }
              window.open(clickthroughUrl, newTab ? '_blank' : '_self')
            }}
          >
            <Body.Regular400>{ctaLabel} →</Body.Regular400>
          </a>
        </div>
      )
    }
  }

  const textClasses = [styles.text]
  const textImageClass =
    imageStyle === 'small' ? styles.imageSmall : styles.imageLarge
  textClasses.push(textImageClass)

  const textContent = (
    <div className={textClasses.join(' ')}>
      {displayEyebrow && eyebrow && (
        <div className={styles.eyebrow}>
          <Body.Medium500>{eyebrow}</Body.Medium500>
        </div>
      )}
      {heading && (
        <div className={styles.heading}>
          <TitleLarge.Sans.Medium500>{heading}</TitleLarge.Sans.Medium500>
        </div>
      )}
      {displaySubHeading && (
        <div className={styles.subHeading}>
          <TitleSmall.Sans.Regular400>
            <Markdown
              input={subHeading}
              allowedMarkdownTypes={
                MARKDOWN_NODE_RESTRICTION_SETS.BASIC_TEXT_WITH_LINKS
              }
            />
          </TitleSmall.Sans.Regular400>
        </div>
      )}
    </div>
  )

  const imageClasses = [styles.imageWrapper]
  if (hideMediaMobile) {
    imageClasses.push(styles.hideMobile)
  }
  const imageStyleClass =
    imageStyle === 'small' ? styles.imageSmall : styles.imageLarge
  imageClasses.push(imageStyleClass)

  const imageContent = (
    <div className={imageClasses.join(' ')}>
      <figure>
        {imageStyle === 'large' && (
          <CloudinaryImage
            width={[270, 460, 750, 850]}
            height={[197, 336, 547, 620]}
            publicId={groupImage}
            alt={imageAlt}
            crop="fit"
          />
        )}
        {imageStyle === 'small' && (
          <CloudinaryImage
            width={[270, 460, 325, 460]}
            height={[197, 336, 215, 300]}
            publicId={groupImage}
            alt={imageAlt}
            crop="fit"
          />
        )}
      </figure>
    </div>
  )
  const wrapperStyle = {
    backgroundColor: background[backgroundColor],
  }

  return (
    <div style={wrapperStyle}>
      <Layout.HorizontallyPaddedContainer>
        <div className={styles.componentWrapper}>
          <div className={styles.wrapper}>
            <div className={styles.textWrapper}>
              {textContent}
              {cta && displayCtaPosition === 'above' && <>{cta}</>}
              {cta && displayCtaPosition === 'below' && (
                <div className={styles.hideMobile}>{cta}</div>
              )}
            </div>
            {imageContent}
            {cta && displayCtaPosition === 'below' && (
              <div className={styles.ctaBelow}>{cta}</div>
            )}
          </div>
          {secondaryImage &&
            secondaryImage.groupImage &&
            secondaryImage.imageAlt && (
              <div className={styles.secondaryImageWrapper}>
                <CloudinaryImage
                  publicId={secondaryImage.groupImage}
                  alt={secondaryImage.imageAlt}
                  crop="fit"
                  width={[327, 896, 896, 1280]}
                  height={[245, 504, 504, 720]}
                />
              </div>
            )}
        </div>
      </Layout.HorizontallyPaddedContainer>
    </div>
  )
}

SupportedImage.propTypes = {
  moduleData: PropTypes.shape({
    groupImage: PropTypes.string.isRequired,
    imageStyle: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    hideMediaMobile: PropTypes.bool,
    eyebrow: PropTypes.string,
    displayEyebrow: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string,
    displaySubHeading: PropTypes.bool,
    displayCtaPosition: PropTypes.string,
    displayCta: PropTypes.bool,
    ctaLabel: PropTypes.string,
    ctaStyle: PropTypes.string,
    clickthroughUrl: PropTypes.string,
    newTab: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    secondaryImage: PropTypes.shape({
      groupImage: PropTypes.string,
      imageAlt: PropTypes.string,
    }),
  }),
}

export default SupportedImage
